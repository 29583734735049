<template>
  <v-app>
    <v-main>
      <v-container class="py-10">
        <div class="text-center mx-auto my-auto">
        
          <v-img
            src="../assets/404.svg"
            width="500"
            class="mx-auto mt-10"
          ></v-img>
          <h1 class="display-4 text-weight-bold text-primary">
            404 Pagina niet gevonden
          </h1>
          <p>We hebben deze pagina niet kunnen vinden.</p>
          <v-btn to="/" elevation="0" color="primary" class="ma-4 btn"
            >Terug naar dashboard</v-btn
          >
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
};
</script>

<style></style>
